/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'stoplights-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M6 0a2 2 0 00-2 2H2c.167.5.8 1.6 2 2v2H2c.167.5.8 1.6 2 2v2H2c.167.5.8 1.6 2 2v1a2 2 0 002 2h4a2 2 0 002-2v-1c1.2-.4 1.833-1.5 2-2h-2V8c1.2-.4 1.833-1.5 2-2h-2V4c1.2-.4 1.833-1.5 2-2h-2a2 2 0 00-2-2zm3.5 3.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0m0 4a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0M8 13a1.5 1.5 0 110-3 1.5 1.5 0 010 3"/>',
    },
});
